import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, NavLink } from "component-library"
import { Link, useHistory } from "react-router-dom"
import { ProviderStore, readEnvironment, translate, useViewMode, ViewProvider, ViewMode } from "shared"
import { Fragment, useEffect, useState } from "react"
import Logo from "../assets/leap.svg"
import Logo_short from "../assets/leap-markers.svg"
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch"
import { useKeycloak } from "@react-keycloak/web"
import { Search } from "../search/search"
import "./app.css"

/**
 * React header based on the bootstrap nav bar
 *
 * @export
 * @returns
 */
export default function App(props: { children: any }) {
	const history = useHistory()

	const { keycloak } = useKeycloak()

	const logoURL = readEnvironment("REACT_APP_WHITELABEL_LOGO")

	const [path, setPath] = useState(history.location.pathname)
	const viewMode = useViewMode()

	const [open, setOpen] = useState<boolean | null>(null)

	const [searchOpen, setSearchOpen] = useState(false)

	const isGlobalMapView = path == "/map"

	let width = "0"

	switch (viewMode) {
		case ViewMode.FULL:
		case ViewMode.ICONS:
			width = open ? "300px" : "5rem"
			break
		case ViewMode.HIDDEN:
			width = open ? "100%" : "0"
			break
	}

	let mainWidth = "calc(100% - " + width + ")"

	if (history.location.pathname === "/search" && viewMode !== ViewMode.HIDDEN) {
		history.push("/map")
	}

	useEffect(() => {
		history.listen((location, _) => {
			setPath(location.pathname)
		})
	}, [])

	useEffect(() => {
		if (isGlobalMapView) {
			setSearchOpen(true)
		}
	}, [path])

	// The blur effect has to be set after the component is rendered in Safari in order to work
	const [blur, setBlur] = useState("blur(20px)")
	useEffect(() => {
		setBlur("blur(19px)")
		window.setTimeout(() => {
			setBlur("blur(20px)")
		}, 100)
	}, [open, viewMode])

	return (
		<>
			<div className="header" id="header">
				<div className="header-top-row">
					<div className="header-container">
						<div className="box1">
							<div className="menu-icon">
								<FontAwesomeIcon className={`sidebar-open space`} icon={faBars} onClick={() => setOpen(!open)} />
							</div>
							{viewMode !== ViewMode.HIDDEN && <Search />}
						</div>
						<div className="box2">
							{viewMode !== ViewMode.HIDDEN && (
								<Link to={"/"}>
									<img src={logoURL.length > 0 ? logoURL : Logo} alt="LEAP" className="sidebar-logo" />
								</Link>
							)}
							{viewMode === ViewMode.HIDDEN && (
								<Link to={"/"} onClick={() => viewMode === ViewMode.HIDDEN && setOpen(false)}>
									<img src={Logo_short} alt="LEAP" className="sidebar-logo" />
								</Link>
							)}
							<div className="header-icons">
								{viewMode === ViewMode.HIDDEN && (
									<Button
										icon={faSearch}
										kind={"primary"}
										size={"small"}
										onClick={() => {
											setSearchOpen(!searchOpen)
										}}
									/>
								)}
								<Link to="/settings" onClick={() => viewMode === ViewMode.HIDDEN && setOpen(false)}>
									<Button icon={faCog} kind={"primary"} size={"small"} />
								</Link>
								<Button
									icon={faSignOutAlt}
									kind={"primary"}
									size={"small"}
									label={viewMode !== ViewMode.HIDDEN ? translate("t-action-logout") : ""}
									onClick={() => {
										keycloak.logout()
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				{viewMode === ViewMode.HIDDEN && (
					<div>
						<Search open={searchOpen} setOpen={setSearchOpen} setSidebarOpen={setOpen} />
					</div>
				)}
			</div>
			<div
				className="sidebar"
				style={{ width: width, backdropFilter: blur, WebkitBackdropFilter: blur }}
				onTransitionEnd={() => {
					ProviderStore.emitEvent("sidebar-width-change")
					window.dispatchEvent(new Event("resize"))
				}}
			>
				{ProviderStore.getViewProviders("root")
					.providers.filter((prov: ViewProvider) => !prov.hidden)
					.map(provider => provider as ViewProvider & { additionalData: { group: string } })
					.sort((a, b) => b.priority - a.priority)
					.map((provider, index, providersArray) => {
						const isLastInGroup =
							index === providersArray.length - 1 ||
							provider.additionalData?.group !== providersArray[index + 1]?.additionalData?.group
						return (
							<Fragment key={provider.uniqueId}>
								<Link
									key={`/${provider.uniqueId}`}
									to={`/${provider.uniqueId.toLowerCase().replaceAll(" ", "-")}`}
									onClick={() => {
										if (viewMode === ViewMode.HIDDEN) {
											setOpen(false)
										}
									}}
								>
									<NavLink
										icon={provider.icon!}
										active={path.startsWith(`/${provider.uniqueId.toLowerCase().replaceAll(" ", "-")}`)}
										size={viewMode !== ViewMode.HIDDEN && !open ? "icon" : "full"}
										label={provider.name}
									/>
								</Link>
								{isLastInGroup && index < providersArray.length - 1 && <hr className="separator" />}
							</Fragment>
						)
					})}
			</div>
			<div className="main" style={viewMode !== ViewMode.HIDDEN ? { width: mainWidth, left: width } : {}}>
				{props.children}
			</div>
		</>
	)
}
