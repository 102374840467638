import { ProviderStore, translate, useI18n, ViewProvider } from "shared"
import { faFileContract } from "@fortawesome/free-solid-svg-icons/faFileContract"
import { useMemo } from "react"

// @ts-ignore
import licenses from "./THIRD-PARTY-LICENSES.txt?raw"

export class Licenses extends ViewProvider {
	groupName = "settings"
	name = translate("t-licenses-tab-title")
	icon = faFileContract
	priority = 10
	uniqueId = "licenses"
	component = () => {
		const licenceList = useMemo(() => {
			let list = (licenses as string).split("\n")
			list = list.slice(3, list.length - 5)
			return list
		}, [licenses])

		return (
			<>
				<h3>{translate("t-licenses-title")}</h3>
				<p>{translate("t-licenses-description")}</p>
				<br />
				{licenceList.map((line, i) => (
					<span
						style={{
							fontFamily: "monospace, monospace",
							display: "block",
							lineHeight: "2",
						}}
						key={i}
					>
						{line}
					</span>
				))}
			</>
		)
	}
}

ProviderStore.register(new Licenses())
