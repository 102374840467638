import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { faDotCircle } from "@fortawesome/free-solid-svg-icons/faDotCircle"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { useEffect, useState, useMemo } from "react"
import { ProviderStore, readEnvironment, translate, useI18n, ViewProvider } from "shared"
import { LoadingIndicator, Table } from "component-library"
import { AvailabilityApi, AvailabilityDTO, Configuration } from "tenant-service-api-client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestion } from "@fortawesome/free-solid-svg-icons"

export class AvailabilityInfoViewProvider extends ViewProvider {
	groupName = "settings"
	name = translate("t-modules-name")
	icon = faDotCircle
	priority = 500
	uniqueId = "modules"
	component = () => {
		const availabilityApi = new AvailabilityApi(
			new Configuration({
				basePath: `https://${readEnvironment("FQDN")}${readEnvironment("REACT_APP_BACKEND_PATH_TENANT_SERVICE")}`,
			}),
		)

		let [availability, setAvailability] = useState<AvailabilityDTO[]>([])
		let [loading, setLoading] = useState(true)

		function loadAvailability() {
			setLoading(true)
			availabilityApi
				.getAvailability()
				.then(availability => {
					setAvailability(availability)
				})
				.finally(() => setLoading(false))
		}

		useEffect(() => {
			loadAvailability()
		}, [])

		const tableData = useMemo(
			() =>
				availability.map(a => ({
					id: a.id,
					name: a.name,
					description: a.description,
					available: a.availability,
				})),
			[availability],
		)

		return loading ? (
			<LoadingIndicator position="center" />
		) : (
			<Table
				pagable={false}
				columns={[
					{
						Header: translate("t-name-name"),
						accessor: "name",
						sortable: false,
						searchable: false,
					},
					{
						id: "description",
						Header: translate("t-description-name"),
						accessor: "description",
						sortable: false,
						searchable: false,
						Cell: (props: { value: string }) => {
							return <>{props.value ?? <i>No description available</i>}</>
						},
					},
					{
						id: "availability",
						Header: translate("t-availability-name"),
						accessor: "availability",
						sortable: false,
						searchable: false,
						Cell: (props: { value: boolean }) => {
							if (props.value === true) {
								return <FontAwesomeIcon icon={faCheck} style={{ color: "var(--success)" }} />
							} else if (props.value === false) {
								return <FontAwesomeIcon icon={faTimes} style={{ color: "var(--danger)" }} />
							} else {
								return (
									<FontAwesomeIcon
										icon={faQuestion}
										title={translate("t-availability-unknown")}
										style={{ color: "var(--disabled-color)" }}
									/>
								)
							}
						},
					},
				]}
				data={availability}
			/>
		)
	}
}

ProviderStore.register(new AvailabilityInfoViewProvider())
