import { Notification, readEnvironment, translate } from "shared"

const open = XMLHttpRequest.prototype.open
const fetch = window.fetch

/**
 * Automatically adds the Keycloak access token to all fetch or XMLHttpRequests
 *
 * @param {string} accessToken Keycloak access token
 */
const setupWebrequests = (accessToken: string, notificationConsumer: (notification: Notification) => void) => {
	XMLHttpRequest.prototype.open = function (
		method: string,
		url: string | URL,
		async?,
		username?: string | null | undefined,
		password?: string | null | undefined,
	) {
		open.call(this, method, url, !!async, username, password)
		const urlString = typeof url === "string" ? url : url.href
		if (!urlString.includes("/api/omlox") && !urlString.includes("/auth/realms")) {
			this.setRequestHeader("x-tenant-id", readEnvironment("REACT_APP_TENANT"))
			this.setRequestHeader("x-stage", readEnvironment("REACT_APP_STAGE"))
		}
		if (accessToken) {
			this.setRequestHeader("Authorization", `Bearer ${accessToken}`)
		}
	}

	window.fetch = (input: RequestInfo | URL, init?: RequestInit | undefined) => {
		var additionalHeaders = {}

		const urlString = typeof input === "string" ? input : input instanceof URL ? input.href : input.url

		if (!urlString.includes("/api/omlox") && !urlString.includes("/auth/realms")) {
			additionalHeaders = {
				"x-tenant-id": readEnvironment("REACT_APP_TENANT"),
				"x-stage": readEnvironment("REACT_APP_STAGE"),
			}
		}

		return fetch(input, {
			...init,
			headers: {
				...init?.headers,
				Authorization: `Bearer ${accessToken}`,
				...additionalHeaders,
			},
		}).then(result => {
			if (result.status === 403 || result.status === 401) {
				notificationConsumer(
					new Notification(
						translate("t-generic-authorisation-error-title"),
						translate("t-generic-authorisation-error-description"),
						"warning",
					),
				)
			}
			return result
		})
	}
}

export default setupWebrequests
