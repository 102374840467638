import { useKeycloak } from "@react-keycloak/web"
import { LoadingIndicator } from "component-library"
import { useState, useEffect } from "react"

export function KeycloakLoadingIndicator(props: { children: any }) {
	let { keycloak } = useKeycloak()

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		keycloak.onAuthError = error => {
			console.log(error)
		}
		keycloak.onAuthSuccess = () => {
			setLoading(false)
		}
		let interval = window.setInterval(() => {
			if (!loading) {
				window.clearInterval(interval)
				return
			}
			if (!!keycloak && !!keycloak.idToken) {
				console.debug("Manually detected keycloak status")
				setLoading(false)
				window.clearInterval(interval)
			}
		}, 100)
	}, [keycloak])

	return <>{!loading ? props.children : <LoadingIndicator position="center" />}</>
}
