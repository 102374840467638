import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import {
	Checkbox,
	getLocalStandardUnit,
	getStandardUnits,
	StandardUnit,
	STANDARD_UNITS,
	UnitDisplay,
	UnitIdentifier,
	unitToIdentifier,
} from "component-library"
import { useMemo, useState } from "react"
import {
	PersistenceMethod,
	ProviderStore,
	readPersistentState,
	translate,
	useI18n,
	ViewProvider,
	writePersistentState,
} from "shared"

import "./localisationSettings.css"

export class LocalisationSettings extends ViewProvider {
	groupName = "general-settings"
	name = translate("t-standard-units-name")
	icon?: IconDefinition = undefined
	uniqueId = "localisation-settings"
	priority = 80
	component = () => {
		const { language } = useI18n()

		const [update, setUpdate] = useState(false)

		let standardUnits = useMemo(() => {
			// Get all unique standard unit types
			const unitTypes = [...new Set(STANDARD_UNITS.map(standardUnit => standardUnit.type))]

			const standardUnits = new Map<
				string,
				{ userPreference: string[]; languageDefaults: StandardUnit[]; units: StandardUnit[] }
			>()
			unitTypes.forEach(unitType => {
				let userPreference = readPersistentState<string[]>(
					[],
					unitType + "-preference",
					PersistenceMethod.LOCAL_STORAGE,
					{ initialNull: true },
				)
				let languageDefaults = getLocalStandardUnit(unitType, language.locale)
				standardUnits.set(unitType, { userPreference, languageDefaults, units: getStandardUnits(unitType) })
			})
			return standardUnits
		}, [language, update])

		return (
			<>
				{translate("t-standard-units-info")}
				<br />

				{[...standardUnits.keys()]
					.filter(unitType => standardUnits.get(unitType)!.units.length > 1)
					.map(unitType => {
						let standardUnit = standardUnits.get(unitType)!

						return (
							<div key={unitType}>
								<br />
								<h5>{translate(`t-${unitType}-name`)}</h5>
								<Checkbox
									checked={standardUnit.userPreference === null}
									label={translate("t-action-use-language-default")}
									onChange={value => {
										console.log(value)
										if (value) {
											writePersistentState(null, unitType + "-preference", PersistenceMethod.LOCAL_STORAGE)
										} else {
											console.log(standardUnit.languageDefaults.map(su => unitToIdentifier(su.unit)))
											writePersistentState(
												standardUnit.languageDefaults.map(su => unitToIdentifier(su.unit)),
												unitType + "-preference",
												PersistenceMethod.LOCAL_STORAGE,
											)
										}
										setUpdate(!update)
									}}
								/>
								<div
									className={[
										"localisation-settings-options",
										standardUnit.userPreference === null ? "grayed-out" : "",
									].join(" ")}
								>
									{standardUnit.units.map(unit => (
										<div
											key={unitToIdentifier(unit.unit)}
											className={
												(standardUnit.userPreference !== null &&
													standardUnit.userPreference.includes(unitToIdentifier(unit.unit))) ||
												(standardUnit.languageDefaults
													.map(unit => unitToIdentifier(unit.unit))
													.includes(unitToIdentifier(unit.unit)) &&
													standardUnit.userPreference === null)
													? "selected"
													: ""
											}
											onClick={() => {
												let identifier = unitToIdentifier(unit.unit)
												if (standardUnit.userPreference === null) {
													standardUnit.userPreference = []
												}
												if (standardUnit.userPreference.includes(identifier)) {
													writePersistentState(
														standardUnit.userPreference.filter(id => id !== identifier),
														unitType + "-preference",
														PersistenceMethod.LOCAL_STORAGE,
													)
												} else {
													writePersistentState(
														[...standardUnit.userPreference, identifier],
														unitType + "-preference",
														PersistenceMethod.LOCAL_STORAGE,
													)
												}
												setUpdate(!update)
											}}
										>
											<div>
												<UnitDisplay unit={unit.unit} abbreviated={false} />
											</div>
											{/*unitToIdentifier(standardUnit.languageDefault.unit) === unitToIdentifier(unit.unit) && (
												<>
													<div className="standard">{translate("t-default-name")}</div>
												</>
											)*/}
										</div>
									))}
								</div>
							</div>
						)
					})}
			</>
		)
	}
}

ProviderStore.register(new LocalisationSettings())
